import {request} from "../../util/Request"
import qs from "qs"

export default {
    data() {
        return {
            exportModal: {
                id: null,
                visibility: false,
                columns: [
                    {id: 'dealer_id', label: this.$t('column.dealerId'), show: true},
                    {id: 'name', label: this.$t('column.name'), show: true},
                    {id: 'phone', label: this.$t('column.phone'), show: true},
                    {id: 'location', label: this.$t('column.location'), show: true},
                    {id: 'email', label: this.$t('column.email'), show: true},
                    {id: 'fax', label: this.$t('column.fax'), show: true},
                    {id: 'zones', label: this.$t('title.zones'), show: true},
                    {id: 'status', label: this.$t('title.status'), show: true},
                ],
                headers: {
                    [this.$t('column.dealerId')]: 'dealer_id',
                    [this.$t('column.name')]: 'name',
                    [this.$t('column.phone')]: 'phone',
                    [this.$t('column.location')]: 'location',
                    [this.$t('column.email')]: 'email',
                    [this.$t('column.fax')]: 'fax',
                    [this.$t('column.zones')]: 'zones',
                    [this.$t('column.status')]: 'status',
                }
            },
        }
    },
    methods: {
        handleExcelExportClick(id) {
            this.exportModal.id = id
            this.exportModal.visibility = true
        },
        async handleExcelExportSubmitClick() {
            let columns = _.filter(this.exportModal.columns, (item) => item.show).map(item => item.id)
            const headers = {}
            _.map(this.exportModal.headers, (index, item) => {
                if (index && _.includes(columns, index)) {
                    headers[item] = index
                }
            })

            this.exportModal.headers = headers
            const extraParams = this.getExtraParams() // from listingMixin
            let newListQueryParams = this.listQueryParams // from listingMixin
            const params = {...newListQueryParams, ...extraParams}
            const response = await request({
                method: "get",
                url: 'dealers',
                params: params,
                paramsSerializer: ((params) => qs.stringify(params)),
            })

            const {data} = response
            const rows = _.map(data, (item, index) => {
                const zones = []

                if(item.location_zone && item.location_zone.name)
                    zones.push(item.location_zone.name)

                _.map(item.dealer_additional_locations, (it) => {
                    if(it.location_zone && it.location_zone.name) {
                        zones.push(item.location_zone.name)
                    }
                })

                return _.pick({
                    dealer_id: this.$global.val(item.dealer_id),
                    name: this.$global.val(item.name),
                    phone: this.$global.val(item.phone),
                    location: this.$global.val(item.main_location.label),
                    email: this.$global.val(item.email),
                    fax: this.$global.val(item.fax),
                    zones: zones.join(', '),
                    status: (item.is_active > 0 ? this.$t('msc.active') : this.$t('msc.de_active')),
                }, columns)
            })
            return rows;
        },
        handleExcelExportCancelClick() {
            this.exportModal.visibility = false
            this.exportModal.id = null
        },
        handleExcelExportSelectAllClick() {
            _.map(this.exportModal.columns, (item, index) => {
                this.exportModal.columns[index].show = true
            })
        },
        handleExcelExportUnSelectAllClick() {
            _.map(this.exportModal.columns, (item, index) => {
                this.exportModal.columns[index].show = false
            })
        },
    },
}
